import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Subscribe() {
    const {  user  } = useAuth0();
    return(<>
    
        <h3 className="mt-5 text-center">Please Subscribe to listen Dars-e-Quraan</h3>
        <h6 className="mt-3 text-center">Your email: <mark className="rounded-2 p-2"> { user.email } </mark></h6>
        <div className="text-center"><a href="https://buy.stripe.com/28o7tq19e8pQg6Y8wy" className="btn btn-secondary btn-sm mt-3">Subscribe</a></div>
        {/* <form action="/data/post" method="POST">
            <label htmlFor="email" className="text-center">Your email: { user.email }</label>

            <input className="form-control mb-3" />
            
        </form> */}
        <p className="mt-5 text-center">Use above mentioned email ID you used to login to this application at the time of checkout.  Giving any other email ids may not authorize you to view the content even the payment is success and Darulkitab is not responsible for this and you can not get any refunds for this discrepancy</p>
        
    </>);
};

export default Subscribe;