import React from "react";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./Footer";

function DefaultHome() {
  const {
    loginWithRedirect,
  } = useAuth0();


  return (
    <>

      <div className=" text-center">
        <h1 className="mb-4 arabic-font-1">بسم الله الرحمن الرحيم</h1>
        <h6>Dars-E-Quraan</h6>
        <p className="urdu-font-1 font-lg">
          مفسر قرآن حافظ وقاری
          مولانا محمد عامر خان قاسمي
        </p>
        <Button
          className="w-25 btn btn-secondary"
          id="qsLoginBtn"
          color="primary"
          onClick={() => loginWithRedirect()}
          size="sm"
          style={{ marginBottom: '5%' }}
        >
          Log in
        </Button>

        <Footer />


      </div>

    </>
  );
}

export default DefaultHome;
